import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import CardLivePlayer from "@/components/CardLivePlayer/CardLivePlayer.vue";
import DialogAddEditPlayerLive from '@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'TableLivePlayers',

    props: {
        hideCreateButton: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    components: {
        UserDropdown,
        CardLivePlayer,
        DialogAddEditPlayerLive,
        DialogConfirmation,
        EmptyStateNoData,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
            livePlayers: 'livePlayers/livePlayers',
            livePlayersLoading: 'livePlayers/livePlayersLoading',
            selectedPlayer: 'livePlayers/selectedPlayer',
        }),

        numberOfPages() {
            if (!this._.isEmpty(this.livePlayers)) {
                return Math.ceil(this.livePlayers.length / this.itemsPerPage)
            }
        },

        skeletonLoadingComputed() {
            return this.$vuetify.breakpoint.mdAndUp
                ? 12
                : 6;
        }
    },

    data() {
        return this.initialState();
    },

    watch: {
        page: function () {
            this.fetchData();
        },
        itemsPerPage: function () {
            this.fetchData();
        },
        livePlayers: function (players) {
            this.showNextButton = players.length === this.itemsPerPage;
            this.showPreviousButton = this.page > 1;
        }
    }, 

    async created() {
        await this.fetchData();
    },

    methods: {
        initialState() {
            return {
                search: '',
                selectedCountry: null,
                dialogues: {
                    addEditPlayerLive: false,
                    deletePlayerLive: false,
                },
                action: 'create',
                itemsPerPageArray: [50, 100, 200],
                page: 1,
                itemsPerPage: 100,
                typingTimeout: null,
                showNextButton: true,
                showPreviousButton: false,
            };
        },

        async fetchData() {

            let payload = {
                per_page: this.itemsPerPage,
                offset: (this.page - 1) * this.itemsPerPage,
            };

            // Adiciona 'search' ao payload se não estiver vazio
            if (!this._.isEmpty(this.search)) {
                payload.search = this.search;
            }
            
            // Adiciona 'country' ao payload se não estiver vazio
            if (!this._.isEmpty(this.selectedCountry)) {
                payload.country = this.selectedCountry;
            }

            await this.$store.dispatch('livePlayers/fetchLivePlayers', payload);
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        addNewPlayer() {
            this.$store.commit('livePlayers/setSelectedPlayer', null);
            this.action = 'create';
            this.toggleDialog('addEditPlayerLive')
        },

        getCodeFromCountry(name) {
            let output = 'unknown.svg';

            if (!this._.isEmpty(this.countries) && name) {
                let countryObject = this.countries.find(e => e.name == name);
                if (countryObject) {
                    output = countryObject.code + '.svg'
                }
            }

            return output;
        },

        editPlayer(player) {
            this.$store.commit('livePlayers/setSelectedPlayer', player);
            this.action = 'update';
            this.toggleDialog('addEditPlayerLive');
        },

        openDialogConfirmation(player) {
            this.$store.commit('livePlayers/setSelectedPlayer', player);
            this.toggleDialog('deletePlayerLive')
        },

        searchPlayer() {
            // Limpa o temporizador anterior para evitar múltiplas execuções
            clearTimeout(this.typingTimeout);
            // Define um novo temporizador que chama 'searchPlayer' após 1 segundo de inatividade
            this.typingTimeout = setTimeout(() => {
                    this.fetchData()
                }, 1000);
        },

        async deletePlayer() {
            await this.$store.dispatch('livePlayers/deletePlayer', this.selectedPlayer.id);
            this.toggleDialog('deletePlayerLive');
        },


        nextPage () {
            if (this.livePlayers.length === this.itemsPerPage) this.page += 1
        },
        
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },

        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },
  
    }
};
